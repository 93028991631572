import { watch, type Ref } from 'vue'
import { useCartStore } from '@/stores/cartStore'
import { useDonationStore } from '@/stores/donationStore'
import { storeToRefs } from 'pinia'
import router from '@/router'
import { analytics } from '@/firebase'
import { logEvent } from 'firebase/analytics'
import { useAnalyticsItemGenerators } from '../analytics/AnalyticsItemGenerators'

export async function attemptPayment(
  isCheckoutButtonDisabled: Ref<boolean>,
  helpText: Ref<string>,
  fadeOutCallback: () => void
): Promise<{ success: boolean }> {
  const { grandTotal } = storeToRefs(useCartStore())
  const { stripeCheckout, stripeCheckoutSessionId } = storeToRefs(useCartStore())
  const { donationId, donationRecord } = storeToRefs(useDonationStore())
  const { getDonationForSessionId } = useDonationStore()
  const { generateAnalyticsItemsFromCart } = useAnalyticsItemGenerators()

  // Get the session directly from stripeCheckout
  const session = stripeCheckout.value?.session()

  try {
    // Make sure stripeCheckout exists
    if (!stripeCheckout.value) {
      console.error('Stripe checkout not initialized')
      helpText.value = 'Payment system not ready. Please try again.'
      isCheckoutButtonDisabled.value = false
      return { success: false }
    }

    const result = await stripeCheckout.value.confirm({
      redirect: 'if_required' as const,
    })

    if (!result) {
      isCheckoutButtonDisabled.value = false
      return { success: false }
    }

    if (result.type === 'error') {
      helpText.value = result.error.message
      isCheckoutButtonDisabled.value = false
      return { success: false }
    }

    // Handle success
    if (result.type === 'success') {
      if (stripeCheckoutSessionId.value) {
        logEvent(analytics, 'purchase', {
          value: grandTotal.value,
          currency: 'USD',
          transaction_id: stripeCheckoutSessionId.value,
          coupon: session?.discountAmounts?.[0]?.displayName,
          items: generateAnalyticsItemsFromCart(),
        })
        getDonationForSessionId(stripeCheckoutSessionId.value)
      }

      // Show thank you modal first
      fadeOutCallback()
      // Then clear cart
      useCartStore().clearCart()
      donationId.value = null

      // Add watch for donation record to handle redirect
      watch(donationRecord, (newDonationRecord) => {
        if (newDonationRecord) {
          router.push({ name: 'donations' })
        }
      })

      return { success: true }
    }

    return { success: false }
  } catch (error: any) {
    console.error('Payment error:', error)
    if (error?.error?.type === 'card_error' || error?.error?.type === 'validation_error') {
      helpText.value = error.error.message || 'Your card was declined.'
    } else if (error?.message?.includes('elements store')) {
      helpText.value = 'Payment system error. Please try again or refresh the page.'
    } else {
      helpText.value = error?.message || 'An error occurred. Please try again.'
    }
    isCheckoutButtonDisabled.value = false
    return { success: false }
  }
}
