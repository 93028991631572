import { useCartStore } from '@/stores/cartStore'
import stripePromise from '@/stripe'
import { getFunctions, httpsCallable, type HttpsCallableResult } from 'firebase/functions'
import { storeToRefs } from 'pinia'
import { elementsOptions } from './StripeElementsOptions'
import { analytics } from '@/firebase'
import { logEvent } from 'firebase/analytics'
import { useAnalyticsItemGenerators } from '../analytics/AnalyticsItemGenerators'
import { useMemberStore } from '@/stores/memberStore'

interface StripeCheckoutSessionInitializationData {
  sessionId: string
  clientSecret: string
}

const functions = getFunctions()
const initializeCheckoutSessionFunction = httpsCallable(functions, 'api-initializeCheckout')

export async function useStripeCheckout() {
  const {
    cart,
    grandTotal,
    totalQty,
    stripeCheckoutSessionId,
    stripeCheckout,
    checkoutHasShippingAddress,
    checkoutHasPaymentDetails,
  } = storeToRefs(useCartStore())

  const { generateAnalyticsItemsFromCart } = useAnalyticsItemGenerators()

  const { memberRecord } = storeToRefs(useMemberStore())

  const initializeCheckoutSession = async () => {
    console.log('useStripeCheckout - initializeCheckoutSession', memberRecord.value, totalQty.value)
    if (!memberRecord.value || totalQty.value == 0) return

    try {
      const stripe = await stripePromise

      const sessionInitData: HttpsCallableResult<StripeCheckoutSessionInitializationData> =
        (await initializeCheckoutSessionFunction({
          cart: cart.value,
          live: true,
          return_url: window.location.origin + '/donations',
        })) as HttpsCallableResult<StripeCheckoutSessionInitializationData>

      if (sessionInitData) {
        if (stripe) {
          console.log('Initializing Stripe checkout with:', {
            clientSecret: sessionInitData.data.clientSecret,
            elementsOptions,
          })

          stripeCheckout.value = await stripe.initCheckout({
            clientSecret: sessionInitData.data.clientSecret,
            elementsOptions,
          })

          console.log('Stripe checkout initialized:', stripeCheckout.value)

          console.log('Stripe checkout methods:', {
            createElement: !!stripeCheckout.value?.createElement,
            getElement: !!stripeCheckout.value?.getElement,
          })

          // Get the session ID directly from the session object
          const session = stripeCheckout.value.session()
          stripeCheckoutSessionId.value = session?.id || sessionInitData.data.sessionId

          stripeCheckout.value.on('change', (session: any) => {
            stripeCheckoutSessionId.value = session.id

            // Check for shipping details
            if (!checkoutHasShippingAddress.value && session.shipping_details) {
              logEvent(analytics, 'add_shipping_info', {
                value: grandTotal.value,
                currency: 'USD',
                items: generateAnalyticsItemsFromCart(),
              })
              checkoutHasShippingAddress.value = true
            }

            // Check payment status
            if (!checkoutHasPaymentDetails.value && session.status === 'complete') {
              logEvent(analytics, 'add_payment_info', {
                value: grandTotal.value,
                currency: 'USD',
                items: generateAnalyticsItemsFromCart(),
              })
              checkoutHasPaymentDetails.value = true
            }
          })
        } else {
          console.error('Error: Stripe not initialized.')
        }
      }
    } catch (error) {
      console.error('Error fetching checkout session:', error)
      // Optionally handle or throw the error as needed
    }
  }

  return {
    initializeCheckoutSession,
  }
}
