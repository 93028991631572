<script setup lang="ts">
  import { watchDebounced } from '@vueuse/core'
  import { getFunctions, httpsCallable } from 'firebase/functions'
  import { computed, ref, watch } from 'vue'
  import { useMemberStore } from '@/stores/memberStore'
  import { storeToRefs } from 'pinia'
  import { useCartStore } from '@/stores/cartStore'
import { lastEventId } from '@sentry/browser'

  const orderUpdateEmailConsent = ref(true)
  const orderUpdateSMSConsent = ref(true)
  const productUpdateEmailConsent = ref(true)
  const productUpdateSMSConsent = ref(true)
  const preferredName = ref('')
  const lastName = ref('')
  const email = ref('')
  const { memberRecord } = storeToRefs(useMemberStore())
  const { stripeCheckout, isStripeCheckoutReady } = storeToRefs(useCartStore())

  watch(
    memberRecord,
    () => {
      orderUpdateEmailConsent.value = memberRecord.value?.contactConsent?.orderUpdates?.email ?? true
      orderUpdateSMSConsent.value = memberRecord.value?.contactConsent?.orderUpdates?.sms ?? true
      productUpdateEmailConsent.value = memberRecord.value?.contactConsent?.announcements?.email ?? true
      productUpdateSMSConsent.value = memberRecord.value?.contactConsent?.announcements?.sms ?? true
      preferredName.value = memberRecord.value?.preferredName ?? null
      lastName.value = memberRecord.value?.lastName ?? null
      email.value = memberRecord.value?.email ?? null
    },
    { immediate: true }
  )

  watch([orderUpdateEmailConsent, orderUpdateSMSConsent], ([emailConsent, smsConsent]) => {
    if (!emailConsent && !smsConsent) {
      orderUpdateEmailConsent.value = true
    }
  })

  const functions = getFunctions()
  const updateMemberRecordFunction = httpsCallable(functions, 'api-updateMemberRecord')
  const updatePreferences = async () => {
    try {
      console.log('CheckoutContactPreferences - updateMemberRecord start')

      await updateMemberRecordFunction({
        orderUpdateEmailConsent: orderUpdateEmailConsent.value,
        orderUpdateSMSConsent: orderUpdateSMSConsent.value,
        productUpdateEmailConsent: productUpdateEmailConsent.value,
        productUpdateSMSConsent: productUpdateSMSConsent.value,
        preferredName: preferredName.value,
        lastName: lastName.value,
        email: email.value,
      })
    } catch (error) {
      console.error('Error updating contact preferences:', error)
    }
  }

  watchDebounced(
    [
      orderUpdateEmailConsent,
      orderUpdateSMSConsent,
      productUpdateEmailConsent,
      productUpdateSMSConsent,
      preferredName,
      lastName,
      email,
    ],
    updatePreferences,
    { debounce: 3000 }
  )

  watch([email, isStripeCheckoutReady], ([newEmail, isReady]) => {
    if (isReady && stripeCheckout.value?.updateEmail) {
      stripeCheckout.value.updateEmail(newEmail)
    }
  })
</script>

<template>
  <div class="text-lg font-ringside-narrow text-stone-40 pb-2.5 border-b-[5px] border-solid border-stone-400">
    Contact Preferences:
  </div>
  <div class="flex pb-5">
    <form class="flex-1 flex-col"><div class="flex">
      <div class="w-1/2">
        <label
          for="preferredName"
          class="font-ringside-condensed font-bold pl-1 text-sm uppercase"
          >Preferred Name</label
        >
        <input
          id="preferredName"
          required
          v-model="preferredName"
          type="text"
          placeholder="First Name"
          autocomplete="given-name"
          class="w-full mb-5 p-2.5 border-b border-solid border-stone-200 text-base placeholder:font-ringside-narrow placeholder:font-light placeholder:text-stone-400" />
      </div>
    <div class="w-1/2">
        <label
          for="lastName"
          class="font-ringside-condensed font-bold pl-1 text-sm uppercase"
          >Last Name</label
        >
        <input
          id="lastName"
          required
          v-model="lastName"
          type="text"
          placeholder="Last Name"
          autocomplete="family-name"
          class="w-full mb-5 p-2.5 border-b border-solid border-stone-200 text-base placeholder:font-ringside-narrow placeholder:font-light placeholder:text-stone-400" />
      </div>
    </div>
      <label
        for="emailAddress"
        class="font-ringside-condensed font-bold pl-1 text-sm uppercase"
        >Email Address</label
      >
      <input
        id="emailAddress"
        required
        v-model="email"
        type="email"
        placeholder="your@email.com"
        autocomplete="email"
        class="w-full p-2.5 border-b border-solid border-stone-200 text-base placeholder:font-ringside-narrow placeholder:font-light placeholder:text-stone-400 autofill:bg-transparent" />
      <div class="flex gap-5 pt-5">
        <div class="flex-3 pt-1 self-center font-ringside-narrow font- text-stone-400 pl-1 text-xs uppercase">
          Order updates
        </div>
        <ContactPreferenceToggle
          label="Email"
          :consent="orderUpdateEmailConsent"
          @update:consent="orderUpdateEmailConsent = $event" />
        <ContactPreferenceToggle
          label="SMS"
          :consent="orderUpdateSMSConsent"
          @update:consent="orderUpdateSMSConsent = $event" />
      </div>
      <div class="flex gap-5 pt-2.5 pb-5">
        <div class="flex-3 pt-1 self-center font-ringside-narrow font- text-stone-400 pl-1 text-xs uppercase">
          Product Announcements
        </div>
        <ContactPreferenceToggle
          label="Email"
          :consent="productUpdateEmailConsent"
          @update:consent="productUpdateEmailConsent = $event" />
        <ContactPreferenceToggle
          label="SMS"
          :consent="productUpdateSMSConsent"
          @update:consent="productUpdateSMSConsent = $event" />
      </div>
      <div class="text-xxs text-stone-400">
        By selecting SMS you agree to receive transaction notifications and/or general announcements from Method. You
        understand you will receive no more than 4 announcements per year, data rates may apply, reply STOP to opt out.
      </div>
    </form>
  </div>
</template>
<style scoped>
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: all 1000000s ease-in-out 1000000s;
  }
</style>
