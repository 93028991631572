<script setup lang="ts">
  import { ref, watch, onBeforeMount } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useMemberStore } from '@/stores/memberStore'
  import { useCartStore } from '@/stores/cartStore'
  import { scrollToElement } from '../utilities/scrollToElement'
  import { useDonationStore } from '@/stores/donationStore'
  import { analytics, functions } from '@/firebase'
  import { logEvent } from 'firebase/analytics'
  import { useAnalyticsItemGenerators } from '@/utilities/analytics/AnalyticsItemGenerators'
  import { initializeStripeCheckoutElements } from '@/utilities/CheckoutView/initializeStripeCheckoutElements'
  import { attemptPayment } from '@/utilities/CheckoutView/attemptPayment'
  import { httpsCallable } from 'firebase/functions'
  import { useStripeCheckout } from '@/utilities/stripe/useStripeCheckout'

  const { generateAnalyticsItemsFromCart } = useAnalyticsItemGenerators()
  const { memberRecord } = storeToRefs(useMemberStore())
  const { grandTotal, totalQty, stripeCheckout } = storeToRefs(useCartStore())
  const { donationId } = storeToRefs(useDonationStore())
  const updateMemberRecordFunction = httpsCallable(functions, 'api-updateMemberRecord')

  const checkout = ref<HTMLElement | null>(null)

  const isCheckoutButtonDisabled = ref(false)
  const helpText = ref('')

  const ageOfConsent = ref(false)
  const religiousIntent = ref(false)
  const agreeToTerms = ref(false)

  const showThankYouModal = ref(false)
  const handleThankYouModal = () => {
    showThankYouModal.value = true
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  async function checkTermsAndConditions() {
    if (!ageOfConsent.value || !religiousIntent.value || !agreeToTerms.value) {
      helpText.value = 'Please agree to all terms and conditions.'
      return false
    }
    return true
  }

  async function handlePaymentSubmit() {
    if (totalQty.value == 0) return

    isCheckoutButtonDisabled.value = true
    helpText.value = ''
    donationId.value = null

    // First check terms
    const termsAccepted = await checkTermsAndConditions()
    if (!termsAccepted) {
      isCheckoutButtonDisabled.value = false
      return
    }

    try {
      const paymentResult = await attemptPayment(isCheckoutButtonDisabled, helpText, handleThankYouModal)
      if (paymentResult.success) {
        await updateMemberRecordFunction({
          termsAgreed: true,
        })
      }
    } catch (error: any) {
      handlePaymentError(error)
    }
  }

  function handlePaymentError(error: any) {
    if (error.message == 'Additional information required.' && error.errorDetails) {
      const errorMessages = {
        email: 'Please provide a valid email address.',
        shippingAddress: 'Please provide a valid shipping address.',
        paymentDetails: 'Please provide valid payment details.',
      }

      for (const [key, message] of Object.entries(errorMessages)) {
        if (error.errorDetails.includes(key)) {
          helpText.value = message
          isCheckoutButtonDisabled.value = false
          return
        }
      }
    }
  }

  onBeforeMount(async () => {
    const { initializeCheckoutSession } = await useStripeCheckout()

    // Log view_cart analytics first
    logEvent(analytics, 'view_cart', {
      value: grandTotal.value,
      currency: 'USD',
      items: generateAnalyticsItemsFromCart(),
    })

    // Initialize checkout and elements
    await initializeCheckoutSession()
    if (stripeCheckout.value) {
      await initializeStripeCheckoutElements()
    }
  })
</script>
<template>
  <div class="flex flex-col min-h-screen">
    <MessageBar></MessageBar>
    <div class="lg:max-w-screen-lg mx-auto lg:py-10">
      <div class="flex flex-col md:flex-row gap-10 lg:gap-20 mx-auto pb-20 px-5 text-sm">
        <CheckoutCartSummaryBlock @scrollToCheckout="scrollToElement(checkout)" />
        <div
          ref="checkout"
          class="flex-1 flex flex-col">
          <div class="flex-1 flex flex-col gap-5 pt-5">
            <CheckoutContactPreferencesBlock />
            <div class="text-lg font-ringside-narrow text-stone-40 pb-2.5 border-b-[5px] border-solid border-stone-400">
              Shipping Information:
            </div>
            <div id="address-element"></div>
            <div
              class="text-lg font-ringside-narrow pt-5 text-stone-40 pb-2.5 border-b-[5px] border-solid border-stone-400">
              Payment Details:
            </div>
            <div id="payment-element"></div>

            <div>
              <div class="flex">
                <input
                  type="checkbox"
                  v-model="ageOfConsent"
                  required />
                <div class="text-xs pt-0.5 text-stone-400">I am at least 21 years of age.</div>
              </div>
              <div class="flex pt-2.5">
                <input
                  type="checkbox"
                  v-model="religiousIntent"
                  required />
                <div class="text-xs pt-0.5 text-stone-400">Materials are intended for religious purposes.</div>
              </div>
              <div class="flex pt-2.5">
                <input
                  type="checkbox"
                  v-model="agreeToTerms"
                  required />
                <div class="text-xs text-stone-400">
                  I have read and agree to the
                  <router-link
                    to="/legal#membership_agreement"
                    class="underline underline-offset-2 font-medium cursor-pointer decoration-stone-300"
                    >Membership Agreement</router-link
                  >,
                  <router-link
                    to="/legal#terms_and_conditions"
                    class="underline underline-offset-2 font-medium cursor-pointer decoration-stone-300"
                    >Terms & Conditions</router-link
                  >, and
                  <router-link
                    to="/legal#privacy_policy"
                    class="underline underline-offset-2 font-medium cursor-pointer decoration-stone-300"
                    >Privacy Policy</router-link
                  >.
                </div>
              </div>
            </div>
            <div
              v-if="helpText"
              class="text-sm py-2.5 text-red-400">
              {{ helpText }}
            </div>

            <button
              @click="handlePaymentSubmit"
              :class="{ 'bg-stone-200': isCheckoutButtonDisabled }"
              :disabled="isCheckoutButtonDisabled"
              class="rounded-md bg-stone-600 font-light mt-5 py-5 cursor-pointer hover:bg-stone-700 disabled:bg-stone-500 transition-colors duration-200 flex flex-row"
              type="submit">
              <div class="flex-1 text-stone-50 text-center text-sm py-1">
                <template v-if="isCheckoutButtonDisabled">
                  One moment please<span class="ellipsisWave">.</span>
                  <span class="ellipsisWave ellipsisWave-delay-300">.</span>
                  <span class="ellipsisWave ellipsisWave-delay-600">.</span>
                </template>
                <template v-else>
                  Donate
                  {{
                    grandTotal.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  }}
                </template>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1"></div>
    <Footer></Footer>
    <div
      v-if="showThankYouModal"
      :class="{ 'fade-in': showThankYouModal }"
      class="opacity-0 flex flex-col absolute top-0 left-0 bottom-0 right-0 bg-white justify-center">
      <div class="text-center text-5xl font-ringside-narrow font-bold tracking-tight leading-tight pb-10">
        Thank you<span v-if="memberRecord.preferredName">, {{ memberRecord.preferredName }}</span>
        <div class="text-lg pt-5">
          <span class="ellipsisWave mx-0.5">•</span>
          <span class="ellipsisWave ellipsisWave-delay-300 mx-0.5">•</span>
          <span class="ellipsisWave ellipsisWave-delay-600 mx-0.5">•</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .ellipsisWave {
    animation: wave 900ms linear infinite;
  }
  .ellipsisWave-delay-300 {
    animation-delay: 300ms;
  }

  .ellipsisWave-delay-600 {
    animation-delay: 600ms;
  }

  .fade-in {
    opacity: 1;
    transition: opacity 250ms ease-out;
  }

  input[type='checkbox'] {
    @apply appearance-none flex-shrink-0 w-4 h-4 rounded-sm bg-stone-100 shadow-inner border border-solid border-stone-300 mr-2.5 cursor-pointer;
  }
  input[type='checkbox']:checked {
    background-image: url('@/assets/images/checkmark.svg');
    @apply bg-stone-400 bg-contain;
  }
  @keyframes wave {
    0% {
      color: #fafaf9;
    } /* stone-50 */
    25% {
      color: #f5f5f4;
    } /* stone-100 */
    50% {
      color: #e7e5e4;
    } /* stone-200 */
    75% {
      color: #d6d3d1;
    } /* stone-300 */
    100% {
      color: #a8a29e;
    } /* stone-400 */
  }
</style>
