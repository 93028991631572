import { defineStore, storeToRefs } from 'pinia'
import { ref, watch, computed } from 'vue'
import { useMemberStore } from './memberStore'
import { useCatalogStore } from './catalogStore'
import CartCheckoutError from '../utilities/CheckoutView/CartCheckoutError'
import { logEvent, type Item } from 'firebase/analytics'
import { analytics } from '@/firebase'
import { type StripeCheckout } from '@stripe/stripe-js'
import { useAnalyticsItemGenerators } from '@/utilities/analytics/AnalyticsItemGenerators'
import { useStripeCheckout } from '@/utilities/stripe/useStripeCheckout'
export type Cart = { [sku: string]: number }

export const useCartStore = defineStore(
  'cart',
  () => {
    const { catalog } = storeToRefs(useCatalogStore())
    const { findProductBySku } = useCatalogStore()
    const { memberRecord } = storeToRefs(useMemberStore())

    const { generateAnalyticsItem, generateAnalyticsItemsFromCart } = useAnalyticsItemGenerators()

    const cart = ref<Cart>({})
    const stripeCheckoutSessionId = ref<string | undefined>()
    const stripeCheckout = ref<StripeCheckout | undefined>()
    const checkoutHasShippingAddress = ref(false)
    const checkoutHasPaymentDetails = ref(false)
    const paymentStatus = ref('')
    const isStripeCheckoutReady = ref(false)

    const totalQty = computed(() => {
      if (Object.keys(cart.value).length === 0) {
        return 0
      }
      return Object.values(cart.value).reduce((sum, qty) => sum + qty, 0)
    })

    const subtotal = computed(() => {
      return Object.entries(cart.value).reduce((sum, [sku, quantity]) => {
        const price = findProductBySku(sku)?.price
        return sum + (price !== undefined ? quantity * price : 0)
      }, 0)
    })

    const grandTotal = computed(() => {
      return memberRecord.value ? Math.max(0, subtotal.value - memberRecord.value.accountBalance) : subtotal.value
    })

    function addToCart(sku: string) {
      updateQuantity(sku, (cart.value[sku] || 0) + 1)
    }

    async function updateQuantity(sku: string, qty: number) {
      if (qty < 0) {
        console.warn('Invalid quantity:', qty)
        return
      }

      const item = findProductBySku(sku)
      if (!item) {
        return
      }

      const originalQty = cart.value[sku]
      cart.value[sku] = qty

      if (originalQty > qty) {
        logEvent(analytics, 'remove_from_cart', {
          value: (originalQty - qty) * item.price,
          currency: 'USD',
          items: [generateAnalyticsItem(sku, originalQty - qty)].filter((item) => item !== undefined) as Item[],
        })
      } else {
        logEvent(analytics, 'add_to_cart', {
          value: (qty - originalQty) * item.price,
          currency: 'USD',
          items: [generateAnalyticsItem(sku, qty - originalQty)].filter((item) => item !== undefined) as Item[],
        })
      }

      if (originalQty === 0) {
        const { initializeCheckoutSession } = await useStripeCheckout()
        await initializeCheckoutSession()
        return
      }

      if (stripeCheckout.value?.session) {
        const sessionData = stripeCheckout.value.session()
        const lineItemToUpdate = sessionData.lineItems.find((lineItem) => lineItem.name === sku)

        if (lineItemToUpdate) {
          stripeCheckout.value.updateLineItemQuantity({
            lineItem: lineItemToUpdate.id,
            quantity: qty,
          })
        }
      }
    }

    function clearCart() {
      cart.value = catalog.value.reduce(
        (acc, catalogItem) => ({
          ...acc,
          [catalogItem.sku]: 0,
        }),
        {} as { [sku: string]: number }
      )
    }

    watch(
      catalog,
      async (newCatalog) => {
        if (newCatalog) {
          cart.value = newCatalog.reduce(
            (acc, catalogItem) => ({
              ...acc,
              [catalogItem.sku]: cart.value[catalogItem.sku] || 0,
            }),
            {} as { [sku: string]: number }
          )
        }
      },
      { immediate: true }
    )

    watch(memberRecord, async (newMemberRecord) => {
      if (newMemberRecord) {
        const { initializeCheckoutSession } = await useStripeCheckout()
        await initializeCheckoutSession()
      }
    })

    watch(
      [checkoutHasShippingAddress, checkoutHasPaymentDetails],
      ([newShippingAddress, newPaymentDetails], [oldShippingAddress, oldPaymentDetails]) => {
        if (
          oldShippingAddress === false &&
          oldPaymentDetails === false &&
          (newShippingAddress === true || newPaymentDetails === true)
        ) {
          logEvent(analytics, 'begin_checkout', {
            value: grandTotal.value,
            currency: 'USD',
            items: generateAnalyticsItemsFromCart(),
          })
        }
      }
    )

    return {
      cart,
      totalQty,
      subtotal,
      grandTotal,
      stripeCheckoutSessionId,
      stripeCheckout,
      checkoutHasShippingAddress,
      checkoutHasPaymentDetails,
      paymentStatus,
      isStripeCheckoutReady,
      addToCart,
      updateQuantity,
      clearCart,
      CartCheckoutError,
    }
  },
  { persist: true }
)
