import { useCartStore } from '@/stores/cartStore'
import { until } from '@vueuse/core'
import { storeToRefs } from 'pinia'

export async function initializeStripeCheckoutElements() {
  const { stripeCheckout } = storeToRefs(useCartStore())
  let addressElement = null
  let paymentElement = null

  console.log('Initializing elements, stripeCheckout:', stripeCheckout.value)

  // Wait for stripeCheckout to be defined and have the required methods
  await until(
    () =>
      stripeCheckout.value !== undefined &&
      typeof stripeCheckout.value?.getElement === 'function' &&
      typeof stripeCheckout.value?.createElement === 'function'
  ).toBeTruthy()

  // Add non-null assertion since we've verified it exists
  const checkout = stripeCheckout.value!

  try {
    console.log('Getting existing elements')
    addressElement = checkout.getElement('address', 'shipping')
    paymentElement = checkout.getElement('payment')

    console.log('Existing elements:', { addressElement, paymentElement })

    // Only create address element if it doesn't exist
    if (!addressElement) {
      console.log('Creating address element')
      addressElement = checkout.createElement('address', {
        mode: 'shipping',
      })
    }

    // Only create payment element if it doesn't exist
    if (!paymentElement) {
      console.log('Creating payment element')
      paymentElement = checkout.createElement('payment', {
        layout: 'tabs',
      })
    }

    console.log('Mounting elements')
    await Promise.all([addressElement.mount('#address-element'), paymentElement.mount('#payment-element')])

    return { addressElement, paymentElement }
  } catch (error) {
    console.error('Error in stripe elements initialization:', error)
    throw error
  }
}
